<template>
    <div class="Accordion" :class="{ expanded: isExpanded }">
        <div class="toggle" @click="toggle">
            <div class="header">
                <h4>{{ header }}</h4>
                <div class="alert" v-if="!read" />
            </div>
            <img v-if="!isExpanded" src="/images/expand.svg" alt="Expand">
            <img v-if="isExpanded" src="/images/collapse.svg" alt="Expand">
        </div>
        <div v-if="isExpanded" class="content">
            <slot />
        </div>
    </div>
</template>

<script>

    export default {
        name: 'Accordion',
        props: {
            header: String,
            read: { type: Boolean, default: true },
        },
        data() {
            return {
                isExpanded: false,
            }
        },
        methods: {
            toggle() {
                this.$emit('toggle');
                this.isExpanded = !this.isExpanded;
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    .Accordion {
        background-color: color(White);
        border-bottom: 1px solid color(Emperor, 0.15);
    }

    .Accordion.expanded {
        background-color: color(Emperor);
    }

    .toggle {

        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: size(Medium);

    }

    .toggle h4 {
        line-height: 1.25;
        flex-grow: 1;
    }

    .Accordion.expanded .toggle h4 {
        color: color(OldGold);
    }

    .toggle img {
        width: 10px;
    }

    .content {
        padding: 0 calc(#{size(Large)} + 10px) size(Medium) size(Medium);
        color: color(White);
    }

    .header {

        display: flex;
        align-items: center;

        padding-right: size(Medium);

    }

    .alert {
        margin-left: size(Small);
    }

</style>

<style lang="scss">

    @import '../styles/_variables.scss';

    .Accordion .content p {
        margin-top: size(Small);
    }

    .Accordion .content p:first-of-type {
        margin-top: 0;
    }

</style>
