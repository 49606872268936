<template>
    <section id="Alerts">
        <h3 class="no-results" v-if="Object.entries(buckets).length === 0">No results available.</h3>
        <div v-for="bucket in Object.entries(buckets)" :key="bucket[0]">
            <h3>{{ bucket[0] }}</h3>
            <Accordion v-for="alert in bucket[1]" :key="alert.id" :header="header(alert.type)" :read="hasRead(alert.id)" @toggle="markAsRead(alert.id)">
                <div v-if="alert.type === 'invite'">
                    <div v-if="bookStatus(alert.book.id).membership === 'pending'">
                        <p><strong>{{ alert.sender.firstName }} {{ alert.sender.lastName }}</strong> ({{ alert.sender.username }}) has invited you to join <strong>{{ alert.book.name }}</strong>.</p>
                        <div class="invite-buttons">
                            <Button arrow @onClick="accept(alert.book.id)">Accept</Button>
                            <Button theme="red" @onClick="isDeclining = true">Decline</Button>
                            <Confirm v-if="isDeclining" okay="Yes" @okay="decline(alert.book.id)" @cancel="cancel">Are you sure you want to decline the invite to {{ alert.book.name }}?</Confirm>
                        </div>
                    </div>
                    <div v-if="bookStatus(alert.book.id).membership === 'member' || bookStatus(alert.book.id).membership === 'admin'">
                        <p>You accepted the invite to join <strong>{{ alert.book.name }}</strong>.</p>
                    </div>
                    <div v-if="bookStatus(alert.book.id).membership === 'declined'">
                        <p>You declined the invite to join <strong>{{ alert.book.name }}</strong>.</p>
                    </div>
                    <div v-if="bookStatus(alert.book.id).membership === 'removed'">
                        <p>You have been removed from <strong>{{ alert.book.name }}</strong>.</p>
                    </div>
                </div>
                <div v-if="alert.type === 'remove'">
                    <p><strong>{{ alert.sender.firstName }} {{ alert.sender.lastName }}</strong> ({{ alert.sender.username }}) has removed you from <strong>{{ alert.book.name }}</strong>.</p>
                </div>
                <div v-if="alert.type === 'void'">
                    <p><strong>{{ alert.sender.firstName }} {{ alert.sender.lastName }}</strong> ({{ alert.sender.username }}) has voided one of your tickets in <strong>{{ alert.book.name }}</strong>.</p>
                    <div class="invite-buttons">
                        <router-link :to="{ name: 'Bets' }">
                            <Button arrow>See Tickets</Button>
                        </router-link>
                    </div>
                </div>
            </Accordion>
        </div>
    </section>
</template>

<script>

    // Components.
    import Accordion from '@/components/Accordion.vue';
    import Button from '@/components/Button.vue';
    import Confirm from '@/components/Confirm.vue';

    // Firebase.
    import { getAlert, markAsRead } from '@/firebase/alerts.js';
    import { getProfile } from '@/firebase/auth.js';
    import { getBook, acceptInvite, declineInvite } from '@/firebase/books.js';

    const MONTHS = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    export default {
        name: 'Alerts',
        components: {
            Accordion,
            Button,
            Confirm,
        },
        data() {
            return {
                alerts: [],
                isDeclining: false,
            }
        },
        computed: {
            buckets() {

                const buckets = {};

                for (let alert of this.alerts.sort((a, b) => b.createdAt - a.createdAt)) {

                    const date = new Date(alert.createdAt);
                    const bucket = `${ MONTHS[date.getMonth()] } ${ date.getFullYear() }`;

                    if (!buckets[bucket]) buckets[bucket] = [];
                    buckets[bucket].push(alert);

                }

                return buckets;

            },
        },
        mounted() {
            this.$store.dispatch('setNavShadow', false);
        },
        created() {
            if (this.profile.alerts) {
                Object.entries(this.profile.alerts).map(alert => {

                    this.$store.dispatch('updateLoading', 1);

                    getAlert(alert[0]).then(async snapshot => {

                        const val = snapshot.val();
                        const sender = (await getProfile(val.sender)).val();
                        const book = (await getBook(val.book)).val();

                        this.alerts = [
                            ...this.alerts,
                            {
                                ...val,
                                sender,
                                book: {
                                    ...book,
                                    id: val.book,
                                },
                                id: alert[0],
                            }
                        ];

                        this.$store.dispatch('updateLoading', -1);

                    });
                });
            }
        },
        methods: {
            header(type) {
                switch (type) {
                    case 'invite':
                        return 'INVITED TO BOOK';
                    case 'remove':
                        return 'REMOVED FROM BOOK';
                    case 'void':
                        return 'BET VOIDED';
                    default:
                        return null;
                }
            },
            hasRead(id) {
                return this.profile.alerts[id].read;
            },
            markAsRead(id) {
                if (!this.hasRead(id)) markAsRead(this.user.uid, id);
            },
            bookStatus(id) {
                return this.profile.books[id];
            },
            accept(id) {
                acceptInvite(this.user.uid, id);
            },
            decline(id) {
                this.cancel();
                declineInvite(this.user.uid, id);
            },
            cancel() {
                this.isDeclining = false;
            }
        }
    }

</script>

<style lang="scss" scoped>

    @import '../styles/_variables.scss';

    h3 {
        padding: size(Medium);
        border-bottom: 1px solid color(Emperor, 0.15);
    }

    .invite-buttons {
        padding: size(Large) size(Small) size(Medium) size(Large);
    }

    .invite-buttons .Button {
        margin-top: size(Small);
    }

    .invite-buttons .Button:first-of-type {
        margin-top: 0;
    }

    .no-results {
        text-align: center;
        border: none;
    }

</style>
