// Firebase.
import { database } from '@/firebase/config.js';

export async function createBook(userId, name) {

    const book = await database.ref('books').push({
        name,
        createdAt: Date.now(),
        bookie: userId,
        open: true,
    });

    // return database.ref(`profiles/${ userId }/books/${ book.key }`).set('bookie');
    return database.ref(`profiles/${ userId }/books/${ book.key }`).set({
        membership: 'bookie',
        open: true,
        name,
    });

};

export function getBook(id) {
    return database.ref(`books/${ id }`).once('value');
};

export function watchBook(id) {
    return database.ref(`books/${ id }`);
};

export async function inviteToBook(senderId, recipientId, bookId, bookName) {

    await database.ref(`books/${ bookId }/members/${ recipientId }`).set('pending');

    const alert = await database.ref('alerts').push({
        type: 'invite',
        createdAt: Date.now(),
        recipient: recipientId,
        sender: senderId,
        book: bookId,
    });

    await database.ref(`profiles/${ recipientId }/alerts/${ alert.key }`).set({ read: false });
    return database.ref(`profiles/${ recipientId }/books/${ bookId }`).set({
        membership: 'pending',
        open: true,
        name: bookName,
    });

};

export async function acceptInvite(userId, bookId) {
    await database.ref(`books/${ bookId }/members/${ userId }`).set('member');
    return database.ref(`profiles/${ userId }/books/${ bookId }/membership`).set('member');
};

export async function declineInvite(userId, bookId) {
    await database.ref(`books/${ bookId }/members/${ userId }`).set('declined');
    return database.ref(`profiles/${ userId }/books/${ bookId }/membership`).set('declined');
};

export async function promote(userId, bookId) {
    await database.ref(`books/${ bookId }/members/${ userId }`).set('admin');
    return database.ref(`profiles/${ userId }/books/${ bookId }/membership`).set('admin');
};

export async function demote(userId, bookId) {
    await database.ref(`books/${ bookId }/members/${ userId }`).set('member');
    return database.ref(`profiles/${ userId }/books/${ bookId }/membership`).set('member');
};

export async function remove(senderId, recipientId, bookId) {

    const alert = await database.ref('alerts').push({
        type: 'remove',
        createdAt: Date.now(),
        recipient: recipientId,
        sender: senderId,
        book: bookId,
    });

    await database.ref(`books/${ bookId }/members/${ recipientId }`).set('removed');
    await database.ref(`profiles/${ recipientId }/alerts/${ alert.key }`).set({ read: false });
    return database.ref(`profiles/${ recipientId }/books/${ bookId }/membership`).set('removed');

};

export async function closeBook(bookId, members) {
    for (let member of members) await database.ref(`profiles/${ member }/books/${ bookId }/open`).set(false);
    return database.ref(`books/${ bookId }/open`).set(false);
};

export async function reopenBook(bookId, members) {
    for (let member of members) await database.ref(`profiles/${ member }/books/${ bookId }/open`).set(true);
    return database.ref(`books/${ bookId }/open`).set(true);
};
